import {Component} from '@angular/core';
import {Material} from "./material";
import {Font} from "./font";
import {Motif} from "./motif";
import {SignuuService} from './signuu.service';
import {AngularTooltips} from 'angular-tooltips';
/* import { Observable } from 'rxjs'; */

import 'fabric';
declare const fabric: any;

declare global {
  interface Window { webkitURL: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'app';
  tab = 0;

  text = 'Mein Gravurtext';
  fontFamily = 'Maven';
  private textString: string;
  private letterString: string;

  public sessionID: 345456;
  public session: any = {
        identifier: null,
        preview: '',
        svg: ''
    };

  private canvas: any;
  private grid_canvas: any;
  private canvasBasicForm;
  private props: any = {
    canvasFill: '',
    canvasImage: '',
    id: null,
    opacity: null,
    fill: null,
    fontSize: 32,
    lineHeight: 1,
    charSpacing: 100,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Maven',
    TextDecoration: 'none',
    threshold: 128
  };

  private size: any = {
    width: 500,
    height: 500
  };

  private canvasProp: any = {
    engravingColor: '#000000',
  };

  private canvas_bg: any;

  materials = [
    new Material(1, 'Holz', ''),
    new Material(2, 'Acryl', ''),
    new Material(3, 'Metall', '')
  ];

  fonts = [
    new Font(1, 'Maven'),
    new Font(2, 'MarketingScript'),
    new Font(3, 'Matchbook'),
    new Font(4, 'Lintsec'),
    new Font(5, 'Inconsolata'),
    new Font(6, 'England_Hand'),
    new Font(7, 'Cicle'),
    new Font(8, 'Chunkfive'),
    new Font(9, 'Champagne'),
    new Font(10, 'Boston_Traffic'),
    new Font(11, 'PUSAB'),
    new Font(12, 'Pacifico'),
    new Font(13, 'Old_Standard'),
    new Font(14, 'Note_this'),
    new Font(15, 'Lobster_Two'),
    new Font(16, 'Blackout')
  ];

  letterfonts = [
    new Font(1, 'Maven'),
    new Font(2, 'Chunkfive'),
    new Font(3, 'PUSAB'),
    new Font(4, 'Pacifico'),
    new Font(5, 'Old_Standard'),
    new Font(6, 'Lobster_Two'),
  ];

  motifs = [
    new Motif(1, 'Herz', ''),
    new Motif(2, 'Klee', '')
  ];

  constructor(private _signuuService: SignuuService) {
  }

  ngOnInit() {

        /*if(this.sessionID)
            this.getSession();
        else*/
            this.createSession();

    // setup canvas for grid
    let grid_canvas_container = document.getElementById('grid_canvas_container');
    grid_canvas_container.innerHTML = '<canvas id="grid_canvas"></canvas>';
    this.grid_canvas = new fabric.Canvas('grid_canvas', {
      selection: false,
      hoverCursor: 'pointer'
    });
    this.grid_canvas.setWidth(this.size.width);
    this.grid_canvas.setHeight(this.size.height);

    this.renderGrid();

     // setup front side canvas
    this.canvas = new fabric.Canvas('canvas', {
      hoverCursor: 'pointer',
      selection: true,
      selectionColor: 'rgba(175,203,155,0.25)',
      selectionBorderColor: 'rgba(175,203,155,0.5)'
    });
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);

    //this.preloadFonts();
  }

    guid(): string {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c=='x' ? r : (r&0x3|0x8)).toString(16);
        });
        return uuid;
    }

    getSession() {
        console.log(this.sessionID);
        console.log(this.session);
        this._signuuService.getSession(this.session.identifier).subscribe(
            // the first argument is a function which runs on success
            data => {
              this.session.id = data['hydra:member'][0]['id'];
              console.log('use existig session from service');
              console.log(data);
              console.log(data['hydra:member'][0]['id']);
            },
            // the second argument is a function which runs on error
            err => console.log(err),
            // the third argument is a function which runs on completion
            () => console.log('done loading session')
        );
    }

  createSession() {

    this.session.identifier = location.hash.replace('#', '');
    console.log(this.session.identifier);
    if(this.session.identifier !== '') {
      // session = {identifier: this.guid(), preview: ''};
      this.getSession();
      return;
    }

    // let session = {id: '', identifier: this.guid(), preview: ''};
    this.session.identifier = this.guid();
    this._signuuService.createSession(this.session).subscribe(
      data => {
        //console.log('####  session');
        //console.log(data);
        if(typeof data['id'] !== 'undefined') {
            this.session.id = data['id'];
            window.history.pushState( { }, null, '#' + data['identifier'] );
        }
        return true;
      },
      error => {
        console.log("Error saving session!");
        console.log(error);
        return false;
      }
    );
  }

    updateSession() {

      this.session.preview = this.canvas.toSVG();
      this.session.svg = this.canvas.toSVG({}, this.reviverSVG);

        this._signuuService.updateSession(this.session).subscribe(
            data => {
                // refresh the list
              console.log(data);
              console.log('session updated');
                // this.getSession();
                return true;
            },
            error => {
                console.log("Error saving session!");
                // return Observable.throw(error);
            }
        );
    }////
    //  Tabs
    selectTab(setTab): void {
        this.tab = setTab;
    }

  isSelected(checkTab): boolean {
    return this.tab === checkTab;
  }

  ////
  // Canvas


  renderGrid(): void {
    let options = {
      distance: 10,
      width: this.grid_canvas.width,
      height: this.grid_canvas.height,
      param: {
        stroke: '#ebebeb',
        strokeWidth: 1,
        selectable: false
      }
    };
    let gridLen = options.width / options.distance;

    for (var i = 0; i < gridLen; i++) {
      var distance = i * options.distance,
        horizontal = new fabric.Line([distance, 0, distance, options.width], options.param),
        vertical = new fabric.Line([0, distance, options.width, distance], options.param);
      this.grid_canvas.add(horizontal);
      this.grid_canvas.add(vertical);
      if (i % 5 === 0) {
        horizontal.set({stroke: '#cccccc'});
        vertical.set({stroke: '#cccccc'});
      }
    }
  }

  getActiveStyle(styleName, object): object {
    object = object || this.canvas.getActiveObject();
    if (!object) return {};

    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }

  setActiveStyle(styleName, value, object): void {
    object = object || this.canvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    }
    else {
      object[styleName] = value;
    }

    object.setCoords();
    this.canvas.renderAll();
  }

  getActiveProp(name): string {
    let object = this.canvas.getActiveObject();
    if (!object) return '';

    return object[name] || '';
  }

  setActiveProp(name, value): void {
    let object = this.canvas.getActiveObject();
    if (!object) return;

    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }


  addText(): void {
    let textString = this.textString;
    this.props = {
      left: this.canvas.width / 2,
      top: this.canvas.height / 2,
      fontFamily: this.fontFamily,
      angle: 0,
      fill: this.canvasProp.engravingColor,
      scaleX: 1,
      scaleY: 1,
      fontWeight: '',
      charSpacing: 0,
      lineHeight: 1
    };
    let text = new fabric.IText(textString, this.props);
    this.canvas.add(text);
  }

  addSVG(svgSrc): void {

    let self = this;

    fabric.loadSVGFromURL(svgSrc, function (objects, options) {
      let loadedObject = fabric.util.groupSVGElements(objects, options);
      let x = loadedObject.width;
      let y = loadedObject.height;
      loadedObject.set({
        left: self.canvas.width / 2 - x / 2,
        top: self.canvas.height / 2 - y / 2,
        fill: 'rgb(0,0,0)'
      }).setCoords();
            /*
            if (loadedObject.isSameColor && loadedObject.isSameColor() || !loadedObject.paths) {
                loadedObject.setFill(engravingColor);
            } else if (loadedObject.paths) {
                for (var i = 0; i < loadedObject.paths.length; i++) {
                    loadedObject.paths[i].setFill(engravingColor);
                }
            }
             */
      self.canvas.add(loadedObject);
    });
  }

  addLetters(material = 'assets/images/birkensperrholz_1.jpg'): void {

    let self = this;
    let repeat = 'repeat';

    let can_objects = this.canvas.getObjects();
    for (let i = 0; i < can_objects.length; i++) {
      let object = can_objects[i];
      if (object.id === 'basicForm') {
        // remove existing basic form
        this.canvas.remove(object);
      }
    }

    fabric.util.loadImage(material, function(img) {
      text.set('fill', new fabric.Pattern({
        source: img,
        repeat: 'repeat'
      }));
      text.set('shadow', new fabric.Shadow({
        color: 'rgba(0,0,0,0.3)',
        offsetX: 1,
        offsetY: 1,
        blur: 2
      }));
      self.canvas.renderAll();
    });
    let textString = self.letterString;
    let objProp:any = {
      id: 'basicForm',
      left: this.canvas.width / 2,
      top: this.canvas.height / 2,
      fontFamily: this.fontFamily,
      angle: 0,
      stroke: 'rgb(255,0,0)',
      strokeWidth: 0,
      charSpacing: -200,
      lineHeight: 1,
      scaleX: 1,
      scaleY: 1,
      fontSize: '100',
      originX: 'center',
      originY:  'center',
    };

    let text = new fabric.IText(textString, objProp);
    this.canvas.add(text);
  }

  addBasicForm(type = 'square', material = 'assets/images/birkensperrholz_1.jpg'): void {
      let self = this;
      let padding = 10;
      let repeat = 'no-repeat'; // use repeat or no-repeat

      let can_objects = this.canvas.getObjects();
      for (let i = 0; i < can_objects.length; i++) {
        let object = can_objects[i];
        if (object.id === 'basicForm') {
          // remove existing basic form
          this.canvas.remove(object);
        }
      }

      fabric.Image.fromURL(material, function(img) {

          img.scaleToWidth(self.size.width);
          let patternSourceCanvas = new fabric.StaticCanvas();
          patternSourceCanvas.add(img);
          patternSourceCanvas.renderAll();
          let pattern = new fabric.Pattern({
              source: function() {
                  patternSourceCanvas.setDimensions({
                      width: img.getScaledWidth() + padding,
                      height: img.getScaledHeight() + padding
                  });
                  patternSourceCanvas.renderAll();
                  return patternSourceCanvas.getElement();
              },
              repeat: repeat
          });

          let objProp:any = {
              id: 'basicForm',
              left: self.canvas.width / 2,
              top: self.canvas.height / 2,
              height: self.size.height - padding,
              width: self.size.width - padding,
              fill: pattern,
              stroke: 'rgb(255,0,0)',
              strokeWidth: 0,
              opacity: 1,
              originX: 'center',
              originY:  'center',
              shadow: {
                color: 'rgba(0,0,0,0.3)',
                offsetX: 1,
                offsetY: 1,
                blur: 2
              },
              selectable: false
          };

          if(type === 'square') {
              self.canvasBasicForm = self.canvas.add(new fabric.Rect(objProp));
          }

          if(type === 'rect') {
              objProp.height = objProp.height / 1.5;
              self.canvasBasicForm = self.canvas.add(new fabric.Rect(objProp));
          }

          if(type === 'circle') {
              objProp.radius = self.size.width / 2;
              self.canvasBasicForm = self.canvas.add(new fabric.Circle(objProp));
          }

          if(type === 'ellipse') {
              objProp.rx = self.canvas.width / 2;
              objProp.ry = self.canvas.height / 4;
              objProp.angle = 0;

              self.canvasBasicForm = self.canvas.add(new fabric.Ellipse(objProp));
          }

          if(type === 'triangle') {
              self.canvasBasicForm = self.canvas.add(new fabric.Triangle(objProp));
          }

          console.log(objProp);


/*
          this.canvas.add(new fabric.Polygon([
              {x: 185, y: 0},
              {x: 250, y: 100},
              {x: 385, y: 170},
              {x: 0, y: 245} ], {
              left: 0,
              top: 200,
              angle: -30,
              fill: pattern,
              objectCaching: false
          }));
*/
      });


  }

  addCuttingForm(type = 'square'): void {

      let objProp:any = {
        left: this.canvas.width / 2,
        top: this.canvas.height / 2,
        height: this.size.height / 4,
        width: this.size.width / 4,
        fill: '#ffffff',
        stroke: 'rgb(255,0,0)',
        strokeWidth: 0,
        originX: 'center',
        originY:  'center',
        shadow: {
          color: 'rgba(0,0,0,0.3)',
          offsetX: -1,
          offsetY: -1,
          blur: 2}
      };

      let obj = {};

      if(type === 'square') {
        obj = new fabric.Rect(objProp);
        this.canvas.add(obj);
      }

      if(type === 'rect') {
        objProp.height = objProp.height / 1.5;
        this.canvas.add(new fabric.Rect(objProp));
      }

      if(type === 'circle') {
        objProp.radius = objProp.width / 2;
        this.canvas.add(new fabric.Circle(objProp));
      }

      if(type === 'ellipse') {
        objProp.rx = objProp.width / 2;
        objProp.ry = objProp.height / 4;
        this.canvas.add(new fabric.Ellipse(objProp));
      }

      if(type === 'triangle') {
        this.canvas.add(new fabric.Triangle(objProp));
      }

      /*
       this.canvas.add(new fabric.Polygon([
       {x: 185, y: 0},
       {x: 250, y: 100},
       {x: 385, y: 170},
       {x: 0, y: 245} ], {
       left: 0,
       top: 200,
       angle: -30,
       fill: pattern,
       objectCaching: false
       }));
       */
  }



  ////
  // text manipulation
  capitalize(str): string {
    return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
  }

  getText(): string {
    return this.getActiveProp('text');
  }
  setText(value): void {
    this.setActiveProp('text', value);
  }

  getTextAlign(): string {
    return this.capitalize(this.getActiveProp('textAlign'));
  }
  setTextAlign(value): void {
    this.setActiveProp('textAlign', value.toLowerCase());
  }

  getFontFamily(): void {
    this.props.fontFamily = this.getActiveProp('fontFamily');
  }
  setFontFamily(): void {
    this.setActiveProp('fontFamily', this.props.fontFamily);
  }

  getFontSize(): void {
    this.props.fontSize = this.getActiveStyle('fontSize', null);
  }
  setFontSize(): void {
    this.setActiveStyle('fontSize', parseInt(this.props.fontSize), null);
  }

  getCharSpacing(): void {
    this.props.charSpacing = this.getActiveStyle('charSpacing', null);
  }
  setCharSpacing(): void {
    this.setActiveStyle('charSpacing', parseInt(this.props.charSpacing), null);
  }

  getLineHeight(): object {
    return this.props.lineHeight = this.getActiveStyle('lineHeight', null);
  }
  setLineHeight(): void {
    this.setActiveStyle('lineHeight', parseInt(this.props.lineHeight), null);
  }

  //// allgemeine Tools

  removeSelected(): void {
    let activeObjects = this.canvas.getActiveObjects();
    this.canvas.discardActiveObject()
    if (activeObjects.length) {
      this.canvas.remove.apply(this.canvas, activeObjects);
    }
  }

  confirmClear(): void {
    if (confirm('Wirklich nochmal neu anfangen?')) {
     this.canvas.clear();
    }
  }

  sendBackwards(): void {
    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.sendBackwards(activeObject);
    }
  }

  sendToBack(): void {
    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.sendToBack(activeObject);
    }
  }

  bringForward(): void {
    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.bringForward(activeObject);
    }
  }

  bringToFront(): void {
    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.bringToFront(activeObject);
    }
  }

  getFlipX(): object {
    return this.getActiveStyle('flipX', null);
  }
  setFlipX(value): void {
    this.setActiveStyle('flipX', value, null);
  }

  getFlipY(): object {
    return this.getActiveStyle('flipY', null);
  }
  setFlipY(value): void {
    this.setActiveStyle('flipY', value, null);
  }

  getType(): object {
    return this.getActiveStyle('type', null);
  }
  setType(value): void {
    this.setActiveStyle('type', value, null);
  }



  ////
  // fabric.js
  getRandomLeftTop(): object {
    let offset = 50;
    return {
        left: fabric.util.getRandomInt(0 + offset, 700 - offset),
        top: fabric.util.getRandomInt(0 + offset, 500 - offset)
    };
  }

  getRandomColor(): string {
    let self = this;
    let getRandomInt = fabric.util.getRandomInt;
    return (
        self.pad(getRandomInt(0, 255).toString(16), 2) +
        self.pad(getRandomInt(0, 255).toString(16), 2) +
        self.pad(getRandomInt(0, 255).toString(16), 2)
    );
  }

  ////
  // image manupulation

  getThreshold(): void {
    this.getActiveStyle('threshold', null);
  }

  setThreshold(value): void {
    console.log(this.getActiveStyle('threshold', null));
    this.setActiveStyle('threshold', value, null);
    // renderThreshold(value);
  }

  getInvert(): void {
    this.getActiveStyle('invert', null);
  }

  setInvert(value): void {
    this.setActiveStyle('invert', value, null);
  }



  ////
    // Export and import

  // show optimized SVG

  reviverSVG(markup): string {
    //console.log(markup);
    let mark = markup.replace(/fill: url\(#SVGID_.*\);/g, 'fill: none;').replace(/fill: rgb\(255,255,255\);/g, 'fill: none;').replace(/stroke-width: 0;/g, 'stroke-width: 0.05;');
    //console.log('--------------------------');
    //console.log(mark);
    return mark;
  }

  showSVG(): void {
    document.getElementById('svg_container').innerHTML = this.canvas.toSVG({}, this.reviverSVG);
  }

  // file download
  downloadInTab(): void {
    // console.log(this.canvas.toSVG());
    // window.open(
    //   'data:image/svg+xml;utf8,' +
    //   encodeURIComponent(this.canvas.toSVG()));
    // console.log(this.canvas.toSVG())
    // var image = new Image();
    // image.src = this.canvas.toSVG()
    var w = window.open("");
    w.document.write(this.canvas.toSVG());
  }

  download(): void {
    let blob = new Blob([this.canvas.toSVG({}, this.reviverSVG)], {type: 'image/svg+xml;charset=utf-8'}),
      url = window.URL || window.webkitURL;

    let clickEvent = new MouseEvent("click", {
      "view": window,
      "bubbles": true,
      "cancelable": false
    });

    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url.createObjectURL(blob));
    downloadLink.setAttribute('download', this.sessionID + '.svg');
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.dispatchEvent(clickEvent);
    downloadLink.remove();
  }

    saveJSON(): void {
        if(typeof this.session.preview === 'undefined')
            this.session.preview = {};
        this.session.preview = JSON.stringify(this.canvas);
        this.session.svg = this.canvas.toSVG();
        console.log(this.session);
        console.log(this.canvas);
        console.log(this.canvas.toSVG());
    }

    loadJSON(): void {
        this.canvas.loadFromJSON(this.session.preview, function (canvas) {
            canvas.renderAll();
        });
    }

    ////
    // tools
    pad(str, length): string {
      while (str.length < length) {
        str = '0' + str;
      }
      return str;
    }

    preloadFonts(): void {
        for (let font of this.letterfonts) {
          let span = document.createElement('span');
          span.innerHTML = 'abc123 ';
          span.setAttribute('style', 'font-family:' + font.name + ';');
          // span.style.display = "none";
          document.body.appendChild(span);
        }
    }

  /*
   $scope.isAlignLeft = function () {
   return this.getActiveStyle('textAlign') === 'left';
   };
   $scope.toggleAlignLeft = function () {
   this.setActiveStyle('textAlign',
   this.getActiveStyle('textAlign') === 'left' ? 'left' : 'left');
   };
   $scope.isAlignCenter = function () {
   return this.getActiveStyle('textAlign') === 'left';
   };
   $scope.toggleAlignCenter = function () {
   this.setActiveStyle('textAlign',
   this.getActiveStyle('textAlign') === 'center' ? 'left' : 'center');
   };
   $scope.isAlignRight = function () {
   return this.getActiveStyle('textAlign') === 'right';
   };
   $scope.toggleAlignRight = function () {
   this.setActiveStyle('textAlign',
   this.getActiveStyle('textAlign') === 'right' ? 'left' : 'right');
   };

   $scope.getFontFamily = function () {
   return self.getActiveProp('fontFamily').toLowerCase();
   };
   $scope.setFontFamily = function (value) {
   self.setActiveProp('fontFamily', value.toLowerCase());
   };

   $scope.getBgColor = function () {
   return self.getActiveProp('backgroundColor');
   };
   $scope.setBgColor = function (value) {
   self.setActiveProp('backgroundColor', value);
   };

   $scope.getTextBgColor = function () {
   return self.getActiveProp('textBackgroundColor');
   };
   $scope.setTextBgColor = function (value) {
   self.setActiveProp('textBackgroundColor', value);
   };

   $scope.getStrokeColor = function () {
   return this.getActiveStyle('stroke');
   };
   $scope.setStrokeColor = function (value) {
   this.setActiveStyle('stroke', value);
   };

   $scope.getFill = function () {
   return this.getActiveStyle('fill');
   };
   $scope.setFill = function (value) {
   this.setActiveStyle('fill', value);
   };

   $scope.getStrokeWidth = function () {
   return this.getActiveStyle('strokeWidth');
   };
   $scope.setStrokeWidth = function (value) {
   this.setActiveStyle('strokeWidth', parseInt(value, 10));
   };

   $scope.getFontSize = function () {
   return this.getActiveStyle('fontSize');
   };
   $scope.setFontSize = function (value) {
   this.setActiveStyle('fontSize', parseInt(value, 10));
   };

   $scope.getCanvasBgColor = function () {
   return canvas.backgroundColor;
   };
   $scope.setCanvasBgColor = function (value) {
   canvas.backgroundColor = value;
   canvas.renderAll();
   };

   $scope.addCircle = function () {
   var coord = getRandomLeftTop();

   canvas.add(new fabric.Circle({
   left: coord.left,
   top: coord.top,
   fill: '#' + getRandomColor(),
   radius: 50,
   opacity: 0.8
   }));
   };

   $scope.addTriangle = function () {
   var coord = getRandomLeftTop();

   canvas.add(new fabric.Triangle({
   left: coord.left,
   top: coord.top,
   fill: '#' + getRandomColor(),
   width: 50,
   height: 50,
   opacity: 0.8
   }));
   };

   $scope.addLine = function () {
   var coord = getRandomLeftTop();

   canvas.add(new fabric.Line([50, 100, 200, 200], {
   left: coord.left,
   top: coord.top,
   stroke: '#' + getRandomColor()
   }));
   };

   $scope.addPolygon = function () {
   var coord = getRandomLeftTop();

   this.canvas.add(new fabric.Polygon([
   {x: 185, y: 0},
   {x: 250, y: 100},
   {x: 385, y: 170},
   {x: 0, y: 245}], {
   left: coord.left,
   top: coord.top,
   fill: '#' + getRandomColor()
   }));
   };

   $scope.text = 'Mein Gravurtext';
   $scope.fontFamily = 'Maven';


   function prepareImageSize(imageSrc) {

   var img = new Image();
   img.src = imageSrc;
   // Originalbildgröße
   var width = img.naturalWidth;
   var height = img.naturalHeight;
   var margin = 50;
   var scale = 1;

   if (height >= canvas.height) {
   scale = height / (Math.round(canvas.height - margin));
   height = Math.round(height / scale);
   width = Math.round(width / scale);
   }
   if (width >= canvas.width) {
   scale = width / (Math.round(canvas.width - margin));
   width = Math.round(width / scale);
   height = Math.round(height / scale);
   }

   return scale;
   }

   $scope.addUpload = function (imageSrc) {
   alert('huk');
   var scaleFactor = prepareImageSize(imageSrc);

   fabric.Image.fromURL(imageSrc, function (image) {

   image.set({
   left: canvas.width / 2,
   top: canvas.height / 2,
   angle: 0
   })
   .scale(scaleFactor);

   image.setType('image');
   image.setInvert(false);
   //image.filters.push(new fabric.Image.filters.EngraveReady());
   //image.applyFilters(canvas.renderAll.bind(canvas));

   var tres_filter = new fabric.Image.filters.RemoveColor({threshold: 0.2,});
   //applyFilter(0, tres_filter, image);
   canvas.add(image);
   });
   };

   $scope.invert = function (invert) {

   var obj = canvas.getActiveObject();
   if (!obj) return;

   if (invert) {
   obj.filters[0] = (new fabric.Image.filters.EngraveReadyInverted());
   obj.setInvert(true);
   } else {
   obj.filters[0] = (new fabric.Image.filters.EngraveReady());
   obj.setInvert(false);
   }

   obj.applyFilters();
   canvas.requestRenderAll(); // or the old canvas.renderAll() if you prefer or need it
   };

   /*
   var canvas2dBackend = new fabric.Canvas2dFilterBackend();

   fabric.filterBackend = fabric.initFilterBackend();
   fabric.filterBackend = canvas2dBackend;


   (function (global) {
   "use strict";
   var fabric = global.fabric || (global.fabric = {}), filters = fabric.Image.filters,
   createClass = fabric.util.createClass;
   filters.EngraveReady = createClass(filters.BaseFilter, {
   type: "EngraveReady",
   fragmentSource: "precision highp float;\n" + "uniform sampler2D uTexture;\n" + "uniform float uContrast;\n" + "varying vec2 vTexCoord;\n" + "void main() {\n" + "vec4 color = texture2D(uTexture, vTexCoord);\n" + "float contrastF = 1.015 * (uContrast + 1.0) / (1.0 * (1.015 - uContrast));\n" + "color.rgb = contrastF * (color.rgb - 0.5) + 0.5;\n" + "gl_FragColor = color;\n" + "}",
   threshold: 128,
   mainParameter: "threshold",
   applyTo2d: function (options) {

   var myNewColor = getColorArray(engravingColor);
   var imageData = options.imageData, d = imageData.data, len = d.length, i;
   // Pixel des Bildes anhand des Schwellenwertes manipulieren
   for (i = 0; i < len; i += 4) {
   var r = d[i];
   var g = d[i + 1];
   var b = d[i + 2];

   if (0.2126 * r + 0.7152 * g + 0.0722 * b >= this.threshold) {
   d[i] = myNewColor[0];
   d[i + 1] = myNewColor[1];
   d[i + 2] = myNewColor[2];
   }
   else d[i + 3] = 0;
   }
   }

   ,
   getUniformLocations: function (gl, program) {
   return {
   uContrast: gl.getUniformLocation(program, "uContrast")
   };
   },
   sendUniformData: function (gl, uniformLocations) {
   gl.uniform1f(uniformLocations.uContrast, this.contrast);
   }
   });
   fabric.Image.filters.EngraveReady.fromObject = fabric.Image.filters.BaseFilter.fromObject;
   })(typeof exports !== "undefined" ? exports : this);

   * /

   $scope
   .
   rasterize = function () {
   if (!fabric.Canvas.supports('toDataURL')) {
   alert('This browser doesn\'t provide means to serialize canvas to an image');
   }
   else {
   window.open(canvas.toDataURL('png'));
   }
   };

   $scope
   .
   rasterizeSVG = function () {
   window.open(
   'data:image/svg+xml;utf8,' +
   encodeURIComponent(canvas.toSVG({width: 250, height: 250})));
   };

   $scope
   .
   rasterizeJSON = function () {
   $scope.setConsoleJSON(JSON.stringify(canvas));
   };

   $scope
   .
   getHorizontalLock = function () {
   return self.getActiveProp('lockMovementX');
   };
   $scope
   .
   setHorizontalLock = function (value) {
   self.setActiveProp('lockMovementX', value);
   };

   $scope
   .
   getVerticalLock = function () {
   return self.getActiveProp('lockMovementY');
   };
   $scope
   .
   setVerticalLock = function (value) {
   self.setActiveProp('lockMovementY', value);
   };

   $scope
   .
   getScaleLockX = function () {
   return self.getActiveProp('lockScalingX');
   }
   ,
   $scope
   .
   setScaleLockX = function (value) {
   self.setActiveProp('lockScalingX', value);
   };

   $scope
   .
   getScaleLockY = function () {
   return self.getActiveProp('lockScalingY');
   };
   $scope
   .
   setScaleLockY = function (value) {
   self.setActiveProp('lockScalingY', value);
   };

   $scope
   .
   getRotationLock = function () {
   return self.getActiveProp('lockRotation');
   };
   $scope
   .
   setRotationLock = function (value) {
   self.setActiveProp('lockRotation', value);
   };

   $scope
   .
   getOriginX = function () {
   return self.getActiveProp('originX');
   };
   $scope
   .
   setOriginX = function (value) {
   self.setActiveProp('originX', value);
   };

   $scope
   .
   getOriginY = function () {
   return self.getActiveProp('originY');
   };
   $scope
   .
   setOriginY = function (value) {
   self.setActiveProp('originY', value);
   };

   $scope
   .
   sendBackwards = function () {
   var activeObject = canvas.getActiveObject();
   if (activeObject) {
   canvas.sendBackwards(activeObject);
   }
   };

   $scope
   .
   sendToBack = function () {
   var activeObject = canvas.getActiveObject();
   if (activeObject) {
   canvas.sendToBack(activeObject);
   }
   };

   $scope
   .
   bringForward = function () {
   var activeObject = canvas.getActiveObject();
   if (activeObject) {
   canvas.bringForward(activeObject);
   }
   };

   $scope
   .
   bringToFront = function () {
   var activeObject = canvas.getActiveObject();
   if (activeObject) {
   canvas.bringToFront(activeObject);
   }
   };
   /* @todo remove unneeded code
   var pattern = new fabric.Pattern({
   source: '/assets/escheresque.png',
   repeat: 'repeat'
   });
   * /
   $scope
   .
   clip = function () {
   var obj = canvas.getActiveObject();
   if (!obj) return;

   if (obj.clipTo) {
   obj.clipTo = null;
   }
   else {
   var radius = obj.width < obj.height ? (obj.width / 2) : (obj.height / 2);
   obj.clipTo = function (ctx) {
   ctx.arc(0, 0, radius, 0, Math.PI * 2, true);
   };
   }
   canvas.renderAll();
   };

   $scope
   .
   getConsoleJSON = function () {
   return consoleJSONValue;
   };
   $scope
   .
   setConsoleJSON = function (value) {
   consoleJSONValue = value;
   };
   $scope
   .
   getConsoleSVG = function () {
   return consoleSVGValue;
   };
   $scope
   .
   setConsoleSVG = function (value) {
   consoleSVGValue = value;
   };
   $scope
   .
   getConsole = function () {
   return consoleValue;
   };
   $scope
   .
   setConsole = function (value) {
   consoleValue = value;
   };

   $scope
   .
   loadSVGWithoutGrouping = function () {
   _loadSVGWithoutGrouping(consoleSVGValue);
   };
   $scope
   .
   loadSVG = function () {
   _loadSVG(consoleSVGValue);
   };

   var
   _loadSVG = function (svg) {
   fabric.loadSVGFromString(svg, function (objects, options) {
   var obj = fabric.util.groupSVGElements(objects, options);
   canvas.add(obj).centerObject(obj).renderAll();
   obj.setCoords();
   });
   };

   var
   _loadSVGWithoutGrouping = function (svg) {
   fabric.loadSVGFromString(svg, function (objects) {
   canvas.add.apply(canvas, objects);
   canvas.renderAll();
   });
   };


*/
}
